.custom-tabs .nav-link {
  color: gray;
  font-weight: normal;
  border: none !important;
}

.nav-tabs {
  border-bottom: none !important;
}

.custom-tabs .nav-link.active {
  color: black;
  font-weight: bold;
  border: none;
}
